tbody td {
  padding: 10px 14px;
  border-bottom: 1px solid #eee;
  text-align: left;
  white-space: nowrap;
  overflow: visible; /* Allow the dropdown to overflow outside */
  text-overflow: ellipsis;
  z-index: auto; /* Ensure parent elements don’t create unnecessary stacking contexts */

}

tbody tr {
  background-color: white;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  box-sizing: border-box;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.05);
  font-family: var(--font-reg);
  color: var(--grey-dark-color);
  font-size: 14px;
  z-index: 1; /* Ensure parent elements don’t create unnecessary stacking contexts */
  overflow: visible; /* Allow the dropdown to overflow outside */
}
  
tbody tr:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Higher z-index on hover to ensure the dropdown and row come to the front */
}

tbody td:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

tbody td:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.cust-name {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.cust-email {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.received {
  color: var(--teal-light-color);
  font-family: var(--font-bold);
}

.shared {
  color: var(--purple-color);
  font-family: var(--font-bold);
}

.partners {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}