/* HoverIcon.css */
.icon-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    fill: rgb(146, 145, 145);
  }
  
  .tooltip {
    position: absolute;
    bottom: 100%; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 4px;
    padding: 5px;
    font-size: 12px;
    font-family: var(--font-regular);
    white-space: nowrap;
    z-index: 100000;
  }
  
  .tooltip-text {
    margin-bottom: 2px;
  }
  
  .tooltip-label {
    color: #ddd;
    font-size: 11px;
  }
  