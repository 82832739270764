.partner-referral {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .partner-referral .partner-referral-header {
    display: inline-block;
    text-align: center;
    margin-top: 0px;
    font-family: var(--font-bold);
    color: var(--grey-dark-color);
    font-size: 15px;
    background-color: transparent;
    border: none;
}
  
  .partner-referral .referral-footer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 0 5px;
  }

  .referral-footer button {
    flex: 1;
    background-color: var(--teal-medium-color);
    font-size: 12px;
    font-family: var(--font-reg);
    border-radius: 10px;
    height: 35px;
    line-height: 35px;
    margin-top: 5px;
    cursor: pointer;
    color: white;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .referral-footer .copy {
    background-color: var(--purple-color);
  }

  .referral-footer .copy .copy-icon {
    fill: white;
    height: 16px;
    width: 16px;
    margin-left: 3px;
  }

  .referral-footer .record {
    background-color: var(--teal-medium-color);
  }