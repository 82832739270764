.customer-referral {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

.customer-referral .cust-referral-header {
    display: inline-block;
    text-align: center;
    margin-top: 0px;
    font-family: var(--font-bold);
    color: var(--grey-dark-color);
    font-size: 15px;
    background-color: transparent;
    border: none;
}

.contact-form {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
  
.input-full {
    width: 100%;
    padding: 5px;
    font-size: 12px;
    margin-bottom: 5px;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: var(--font-regular);
    border: 1px solid lightgray;
    background-color: var(--grey-light-color);
}

.input-full:focus {
    outline: none;
}
  
.textarea-full {
    width: 100%;
    height: 50px;
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    font-family: var(--font-regular);
    border: 1px solid lightgray;
    background-color: var(--grey-light-color);
    box-sizing: border-box;
}

.textarea-full:focus {
    outline: none;
}
  
.submit-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}
  
.submit-btn:hover {
    background-color: #0056b3;
}  

.customer-referral .cust-note {
    font-family: var(--font-regular);
    border: 1px solid lightgray;
    background-color: var(--grey-light-color);
    border-radius: 5px;
    height: 60px;
    vertical-align: top;
    padding: 5px;
}

.customer-referral .cust-note:focus {
    outline: none;
}


.customer-referral .cust-referral-footer {
    display: flex;
    justify-content: center;
    background-color: var(--teal-medium-color);
    font-size: 14px;
    font-family: var(--font-regular);
    border-radius: 10px;
    height: 30px;
    line-height: 30px;
    margin-left: 15%;
    margin-right: 15%;
    cursor: pointer;
    color: white;
    border: none;
    text-align: center;
}

.customer-referral .acknowledge-statement {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 3px;
}

.acknowledge-statement input {
    margin: 0;
}

.acknowledge-statement p {
    font-size: 9px;
    margin: 0;
}

.warning-text {
    color: red;
    font-size: 9px;
    margin-top: 0px;
  }
  