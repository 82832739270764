/* LoadingScreen.css */
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    opacity: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .circle-container {
    display: flex;
    gap: 15px;
  }
  
  .loading-circle {
    width: 15px;
    height: 15px;
    background-color: var(--teal-medium-color);
    border-radius: 50%;
    animation: pulse 0.6s infinite ease-in-out;
  }
  
  .loading-circle:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .loading-circle:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.3);
      opacity: 0.6;
    }
  }
  