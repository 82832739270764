/* Filo Color Palette */
:root {
    --grey-dark-color: #3B3B3B;
    --teal-dark-color: #1E566C;
    --teal-medium-color: #5795A7;
    --teal-light-color: #79BEC3;
    --grey-light-color: #F2F2F7;
    --grey-medium-color: #afafb3;
    --gold-dark-color: #D2A93A;
    --gold-light-color: #DBBF57;
    --sand-color: #E2DAD6;
    --background-color: #ffffff;
    --purple-color: #5C3D99;
    --blue-dark-color: #36454F;
    --blue-light-color: #B8C3CD;
    --blue-medium-color: #708090;
    --blue-extralight-color: #DFE3EA;

    /* Filo Font Family */
    --font-thin: 'BA-Thin', sans-serif;
    --font-light: 'BA-Light', sans-serif;
    --font-regular: 'BA-Light', sans-serif;
    --font-reg: 'BA-Regular', sans-serif;
    --font-bold: 'BA-Bold', sans-serif;
    --font-extraBold: 'BA-ExtraBold', sans-serif;

    /* Common Font Sizes to normalize the text size across the website */
    --font-size-xs: clamp(10px, 1.1vw, 20px);
    --font-size-s: clamp(12px, 1.3vw, 24px);
    --font-size-m: clamp(14px, 1.5vw, 32px);
    --font-size-l: clamp(24px, 3.0vw, 48px);
    --font-size-xl: clamp(28px, 4.0vw, 56px);
}

/* Filo Font Family */
@font-face {
    font-family: "Almarai-ExtraBold";
    src: url(../Assets/Fonts/Almarai-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Almarai-Bold";
    src: url(../Assets/Fonts/Almarai-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Almarai-Regular";
    src: url(../Assets/Fonts/Almarai-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Almarai-Light";
    src: url(../Assets/Fonts/Almarai-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "BA-Regular";
    src: url(../Assets/Fonts/BuenosAires-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "BA-Bold";
    src: url(../Assets/Fonts/BuenosAires-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "BA-Light";
    src: url(../Assets/Fonts/BuenosAires-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "BA-Thin";
    src: url(../Assets/Fonts/BuenosAires-Thin.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "BA-ExtraBold";
    src: url(../Assets/Fonts/BuenosAires-Black.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}
