/* Detail Header */
.detail-header {
  position: relative;
}

.content-detail .detail-header .chat-title {
  font-family: var(--font-extraBold);
  color: var(--blue-dark-color);
  font-size: clamp(16px, 1.6vw, 26px);
  margin: 0;
  padding-left: 30px;
  padding-bottom: 5px;
  height: 45px;
  display: flex;
  align-items: flex-end;
  border: none;
}

.content-detail .detail-header .chat-title:focus {
  outline: none;
}

/* Chat Container and Font Settings */
.chat-container,
.chat-container * {
  font-family: var(--font-regular);
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 51px); /* Adjust based on detail-header height */
  background-color: var(--grey-light-color);
  position: relative;
}

/* Messages List */
.messages-list {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

/* Message Bubble */
.message-bubble {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

.message-bubble.margin-adjust {
  margin-top: 20px;
}

.message-bubble.sent {
  flex-direction: row-reverse;
}

.message-bubble.received {
  flex-direction: row;
}

/* Profile Picture */
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

/* Message Content */
.message-content {
  display: flex;
  align-items: center;
  max-width: 40%; /* Thinner message bubbles */
  padding: 7px 10px;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle drop shadow */
  word-wrap: break-word;
}

.message-content p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--grey-dark-color);
}

.message-bubble.sent .message-content {
  background-color: var(--teal-light-color);
}

.message-bubble.sent .message-content p {
  color: #fff;
}

/* Timestamp */
.timestamp {
  position: absolute;
  bottom: -18px;
  right: 10px;
  font-size: 0.75em;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Message Input */
.message-input {
  display: flex;
  align-items: center;
  padding: 5px 10% 20px; /* Adjusted padding-top to 5px */
}

.message-input input {
  flex: 1;
  padding: 8px 20px;
  border: none;
  border-radius: 30px;
  background-color: var(--blue-extralight-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  outline: none;
}

.message-input button {
  margin-left: 10px;
  padding: 8px 12px;
  border: none;
  background-color: var(--teal-medium-color);
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.message-input button:hover {
  opacity: 0.8;
}

.message-input .referral-button {
  background-color: var(--purple-color);
  font-size: 26px;
  padding: 0 10px;
}

/* Content Detail */
.content .content-detail {
  overflow: hidden; /* Limit scrolling to chat-container */
}

/* Collab.css */
.business-search-popup {
  position: absolute;
  top: 60px; /* Adjust based on your layout */
  left: 20px; /* Adjust based on your layout */
  right: 20px; /* Adjust based on your layout */
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.business-search-popup ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.business-search-popup li {
  padding: 10px;
  cursor: pointer;
}

.business-search-popup li:hover {
  background-color: #f0f0f0;
}

/* Media Query for Responsive Design */
@media screen and (max-width: 768px) {
  .message-content {
    max-width: 70%;
  }
}