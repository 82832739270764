
  .opportunities-table {
    margin-right: 10px;
    margin-left: 10px;

  }
  
  table {
    width: calc(100%-40px);
    border-collapse: separate;
    border-spacing: 0 8px; /* Adds vertical spacing (10px gap) */
    background-color: transparent; /* Table background is transparent */
  }
  
  thead th {
    padding: 10px;
    padding-left: 15px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    color: grey;
    font-family: var(--font-bold);
    font-size: 13px;
  }

  .detail-header {
    position: relative;
  }
  
  .detail-header .delete-lead {
    position: absolute;
    top: calc(50% - 10px);
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 5px;
}

.detail-header .delete-lead.active {
    cursor: pointer;
}

.detail-header .delete-lead .delete-icon {
    fill: lightgray;
    width: 20px;
    height: 20px;    
}

.detail-header .delete-lead span {
    color: lightgray;
    font-family: var(--font-reg);
    font-size: 13px;
}

.detail-header .delete-icon.active {
    fill: var(--teal-medium-color);
}

.detail-header .delete-lead span.active {
    color: var(--teal-medium-color);
}
  