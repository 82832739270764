
.content-detail {
    display: flex;
    flex-direction: column;
    position: relative;
}

.partner-detail {
    padding-left: 30px;
    padding-bottom: 20px;
}

.content-detail .detail-header {
    background-color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Light bottom border */
}

.content-detail .detail-header h2 {
    font-family: var(--font-extraBold);
    color: var(--blue-dark-color);
    font-size: clamp(16px, 1.6vw, 26px);
    margin: 0;
    padding-left: 30px;
    padding-bottom: 5px;
    height: 45px;
    display: flex;
    align-items: flex-end;
}

.subheader {
    display: flex;
    align-content: center;
    text-align: center;
}

.subheader h2 {
    font-size: clamp(15px, 1.3vw, 20px);
    font-family: var(--font-bold);
    color: grey;
    align-self: center;
}

.subheader p {
    font-size: clamp(10px, 1vw, 15px);
    font-family: var(--font-regular);
    color: grey;
    align-self: center;
    padding-left: 10px;
}

.partner-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    gap: 15px;
    width: 100%;
    padding: 0px;
}

.content-detail .no-partners {
    font-family: var(--font-regular);
    color: var(--grey-dark-color);
}

@media (max-width: 768px) {
    .content-detail .partner-cards {
        justify-content: center;
    }

    .partner-detail {
        padding-right: 20px;
    }

    .subheader {
        margin-left: 10px;
    }
}
