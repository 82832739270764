.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Light grey transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;  /* Ensure it's above other content */
}