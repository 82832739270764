/* General card styles */
.cust-card {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    padding-left: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Avatar styles */
  .cust-card .avatar {
    flex: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cust-card .avatar .initials {
    font-size: 24px;
    font-weight: bold;
    color: white;
  }

  .cust-card .details {
    flex: 4;
    align-self: stretch;
    margin-left: 20px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  /* Input field styles */
  .cust-card .details h3 {
    margin: 1px 0;
    border: none;
    border-radius: 5px;
    padding: 0px 2px;
    font-size: 12px;
    text-align: left;
    color: rgb(102, 102, 102);
  }

  .cust-card #name {
    font-family: var(--font-bold);
  }

  .cust-card #email {
    font-family: var(--font-thin);
    color: rgb(151, 151, 151);
  }

  .cust-card #phone {
    font-family: var(--font-bold);
    background-color: #d6b8eb;
    margin-top: 3px;
    padding: 2px 5px;
    margin-right: 43%;
    color: white;
  }
  