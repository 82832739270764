.referral-wrapper {
    flex: 1;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-dark-color);
    position: relative;
}

.referral-wrapper .card-wrapper {
    width: 300px;
}

.referral-wrapper .powered-by {
    position: absolute;
    bottom: 10px;
    left: calc(50% - 100px);
    font-family: var(--font-light);
    color: grey;
    font-size: 13px;
    width: 200px;
    text-align: center;
}