
.content {
    flex: 1;
    display: flex;
}

.content .content-detail {
    display: flex;
    flex: 1;
    padding-left: 0px;
    background-color: var(--grey-light-color);
    overflow: auto;
    padding-bottom: 0px;
}

@media (max-width: 768px) {
    .content {
        max-height: calc(100vh - 4vh - 100px);
    }
}
