.status-button-container {
    position: relative; /* This ensures the dropdown is positioned relative to the container */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible; /* Allow the dropdown to overflow */
}

.status {
    position: relative; /* Important to ensure the dropdown is positioned relative to the status */
    flex-grow: 0; /* Prevent the status from growing */
    flex-shrink: 1; /* Allow the status to shrink if needed */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1px 7px;
    border-radius: 8px;
    font-size: 13px;
    font-family: var(--font-reg);
    white-space: nowrap;
    color: white;
    cursor: pointer;
    z-index: 2; /* Ensure the status has a lower z-index than the dropdown */
}

.drop-icon {
    margin-left: 2px;
}

.status-dropdown {
    position: absolute;
    top: calc(100% + 2px); /* Dropdown is placed just below the status button */
    left: 0px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: auto;
    z-index: 9; /* Ensure the dropdown is on top of other rows */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visual separation */
}

.status-option {
    padding: 8px 12px;
    cursor: pointer;
}

.status-option:hover {
    background-color: #eee;
}

/* Status color classes */

.status.closed-other {
    background-color: var(--gold-light-color);
}

.status.closed-won {
    background-color: rgb(62, 177, 150);
}

.status.closed-lost {
    background-color: rgb(230, 118, 118);
}

.status.in-progress {
    background-color: var(--purple-color);
}

.status.contacted {
    background-color: rgb(33, 112, 185);
}

.status.new {
    background-color:rgb(85, 205, 205);
}

.status .drop-icon {
    fill: white;
}
