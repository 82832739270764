.code-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .code-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  .code-input:focus {
    border-color: var(--teal-medium-color);
    box-shadow: 0 0 5px rgba(0, 128, 128, 0.5);
  }

  