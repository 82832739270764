@import './variables.css';

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
}

.product-main {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  padding: 0px;
  background-color: var(--blue-dark-color);
  overflow: hidden;
}

@media (max-width: 768px) {
  .product-main {
    flex-direction: column;
  }
}


