html {
  scroll-behavior: smooth;
}

.landing-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px;
  }

  