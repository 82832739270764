.divider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 7vw; /* Adjust the margin to your preference */
  box-sizing: border-box;
}

.divider-line {
  flex: 1;
  height: 3px;
  background-color: var(--teal-medium-color);; /* Adjust color to match your design */
  opacity: 70%;
  border-radius: 2px;
}

.divider-diamonds {
  display: flex;
  gap: 13px; /* Space between the diamonds */
  padding: 0 30px; /* Space around the diamonds, adjusting the gap from the lines */
}

.diamond {
  width: 13px; /* Size of each diamond */
  height: 13px;
  background-color: transparent; /* Adjust color to match your design */
  border: 4px solid var(--teal-medium-color); /* Width, style, and color of the border */
  transform: rotate(45deg); /* Rotate to create a diamond shape */
  border-radius: 3px;
  opacity: 70%;
}
