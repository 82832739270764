.footer-landing {
    background-color: var(--grey-dark-color);
    padding: 10px 20px;
    z-index: 1000;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    border: none;
}

.footer-landing hr {
    height: 0.5px; /* Adjust height as needed */
    border: none; /* Removes default styling */
    background-color: var(--grey-light-color); /* Sets the color */
    width: 80%;
    margin-bottom: 20px;
    margin-top: 0px;
}

.footer-landing .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.footer-landing .footer-logo img {
    height: 40px;
}

.footer-content p {
    margin: 5px;
    padding: 0;
    font-family: var(--font-light);
    font-size: clamp(12px, 1.0vw, 15px);
    color: var(--grey-light-color);;
}

.footer-landing .footer-nav {
    position: relative;
}

.footer-landing .footer-nav ul {
    list-style: none;
    padding: 0;
    margin: 5px;
    margin-bottom: 12px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, 1fr);
    width: 80vw;
    justify-content: center;
    text-align: center;
    gap: 25px;
}

.footer-landing .footer-nav ul li {
    margin-left: 0px;
    text-align: center;
}

.footer-landing .footer-nav ul li a {
    font-family: var(--font-bold);
    font-size: clamp(12px, 1.0vw, 18px);
    letter-spacing: 0.5px;
    text-decoration: none;
    color: white;
}

@media (max-width: 768px) {
    .footer-landing .footer-nav .nav-item {
        display: none;
    }
}
