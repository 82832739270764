header {
    background-color: white;
    padding: 12px 20px;
    z-index: 1000;
    
}

header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .logo {
    display: flex;
    align-items: center;
}

header .logo img {
    height: clamp(30px, 4vw, 50px);
    margin-right: 10px;
}

header nav {
    flex-grow: 1;
    text-align: center;
}

header nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
}

header nav ul li {
    margin: 0 2%;
}

header nav ul li a {
    font-family: var(--font-reg);
    font-size: clamp(10px, 1.2vw, 20px);
    letter-spacing: 0.5px;
    text-decoration: none;
    color: var(--grey-dark-color);
}

@media (max-width: 768px) {
    header .nav {
        display: none;
    }
}

header .auth-buttons {
    display: flex;
    align-items: center;
}

header .auth-buttons a {
    font-family: var(--font-bold);
    font-size: clamp(10px, 1.2vw, 20px);
    letter-spacing: 0.5px;
    text-decoration: none;
    padding: 6px 10px;
    border-radius: 7px;
    margin-left: 0.3em;
    box-sizing: border-box;
}

.header-landing .auth-buttons a.sign-in {
    color: var(--grey-dark-color);
    background-color: transparent;
}

.header-landing .auth-buttons a.demo-request {
    color: var(--background-color);
    background-color: var(--teal-medium-color);
}

.header-landing .hamburger {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    cursor: pointer;
    gap: 5px;
}

.header-landing .hamburger .menu-line {
    width: 18px;
    height: 3px;
    background-color: var(--grey-dark-color);
    margin-left: 15px;
    border-radius: 5px;
}

.hamburger .menu-line.two {
    width: 14px;
}

@media (max-width: 768px) {
    .header-landing .hamburger {
        display: flex;
    }

    .header-landing .auth-buttons a.sign-in {
        display: none;
    }
    
    .header-landing .auth-buttons a.demo-request {
        display: none;
    }


}